<template>
    <div class="projects" data-view>
      <h1>Coming Soon!</h1>
    </div>
</template>

<script>

export default {
  name: 'Projects',
  components: {
  },
};
</script>

<style scoped>

  h1 {
    color: #707070;
    font-family: "Open Sans", sans-serif;
    margin: 20px auto 50px;
  }

  .projects {
    min-height: 50vh;
  }

</style>
