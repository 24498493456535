import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueProgress from 'vue-progress-path';
import App from './App.vue';
import router from './router';
import store from './store';

import icons from './faicons';
import TrackingMixin from './mixins/TrackingMixin';

library.add(...icons);

Vue.component('fa-icon', FontAwesomeIcon);

Vue.use(VueProgress);

Vue.config.productionTip = false;

Vue.mixin(TrackingMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
