<template>
  <div class="nav">
    <ul>
      <li>
        <router-link :to="{name: 'Home'}" >
          Sam
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'About'}">
          About
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'Projects'}" >
          My Projects
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'Contact'}" >
          Contact
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
};
</script>

<style scoped>

  .nav {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    height: 10vh;
    min-height: 50px;
    align-content: center;
  }

  .nav ul {
    width: 400px;
    margin: 1.5em auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
  }

  .nav ul li {
    list-style: none;
    display: flex;
    align-self: center;
    margin: 20px auto;
  }

  a {
    text-decoration: none;
    color: #707070;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    transition: color 300ms ease;
  }

  a:hover {
    color: #202020;
  }

</style>
