import {
  faIcons,
  faChevronDown,
  faCode,
  faDesktop,
  faCodeBranch,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';

import {
  faTwitter,
  faFacebook,
  faGithub,
  faStackOverflow,
} from '@fortawesome/free-brands-svg-icons';

export default [
  faIcons,
  faChevronDown,
  faCode,
  faDesktop,
  faCodeBranch,
  faGraduationCap,
  faTwitter,
  faFacebook,
  faGithub,
  faStackOverflow,
];
