<template>
  <div class="home" data-view>
    <div class="hero">
      <h1>Sam Bebbington</h1>
      <h2>
        Full Stack Developer
      </h2>
      <h3>
        I love making cool s*** and learning new things
      </h3>
    </div>
    <img class="my-beautiful-face" src="../assets/me.png"
         alt="Vector Drawing of Sam Bebbington" />
    <About />
  </div>
</template>

<script>
// @ is an alias to /src
import About from '@/components/About.vue';

export default {
  name: 'Home',
  components: {
    About,
  },
};
</script>

<style scoped>

  .home {
    margin: 0;
    padding: 0;
  }

  .hero {
    font-family: "Open Sans", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #707070;
    width: 100%;
    height: 70vh;
    min-height: 200px;
    max-width: 1000px;
    margin: 0 auto 10vh;
  }

  .hero * {
    margin: 0 0 10px;
  }

  .hero h1 {
    display: flex;
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    text-shadow: 0 0 15px #FFF;
  }

  .hero h2 {
    display: flex;
    font-size: 3em;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 0 15px #FFF;
  }

  .hero h3 {
    display: flex;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
    text-shadow: 0 0 15px #FFF;
  }

  .my-beautiful-face {
    position: absolute;
    right: 10vw;
    bottom: 0;
    height: 50vh;
    min-height: 200px;
    max-height: 50vw;
    z-index: -1;
  }

  @media screen and (max-width: 1000px) {
    .hero {
      height: 60vh;
      margin-bottom: 20vh;
    }
    .my-beautiful-face {
      right: 0;
    }
  }

</style>
