<template>
    <div id="recaptcha"></div>
</template>

<script>
export default {
  name: 'ReCaptcha',
  methods: {
    onLoadCallback() {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.render('recaptcha', { sitekey: '6LceUjodAAAAAMxzXUzUK3nfVcAaldD653SuvoRH' });
      }, 300);
    },
  },
  mounted() {
    const script = document.createElement('script');
    script.onload = this.onLoadCallback;
    script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
    document.head.appendChild(script);
  },
};
</script>

<style scoped>

  #recaptcha {
    margin: 20px 0;
  }

</style>
