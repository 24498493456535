import client from '@/client.js';

// eslint-disable-next-line import/prefer-default-export
export const tracking = (link = null, page = null) => {
  const data = new FormData();
  if (link !== null) data.append('l', link);
  if (page !== null) data.append('p', page);
  return client.post('/t', data);
};

export const contact = (data) => client.post('/contact', data);
