<template>
    <div class="footer">
      <ul>
        <li>
          <a
            href="https://twitter.com/SamBebbington_"
            target="_blank"
            alt="Sam Bebbington Twitter"
            @click="trackLink(1)"
          >
            <fa-icon :icon="['fab', 'twitter']" />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/TheRealSamBebbington"
            target="_blank"
            alt="Sam Bebbington Facebook"
            @click="trackLink(2)"
          >
            <fa-icon :icon="['fab', 'facebook']" />
          </a>
        </li>
        <li class="green">
          <a href="https://www.ecowebhosting.co.uk/" target="_blank" rel="noopener" alt="This Site Plants Trees">
            <img src="https://eco-cdn.co.uk/eco-badge-1.svg" alt="Planting trees every month with Eco Web Hosting">
          </a>
        </li>
        <li>
          <a
            href="https://github.com/GTBebbo"
            target="_blank"
            alt="Sam Bebbington Github"
            @click="trackLink(3)"
          >
            <fa-icon :icon="['fab', 'github']" />
          </a>
        </li>
        <li>
          <a
            href="https://stackoverflow.com/users/7644446/gtbebbo"
            target="_blank"
            alt="Sam Bebbington Stack Overflow"
            @click="trackLink(4)"
          >
            <fa-icon :icon="['fab', 'stack-overflow']" />
          </a>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>

  .footer {
    width: 100%;
    margin: 0;
    padding: 20px 0;
    min-height: 75px;
    background-color: #ff8800;
    background: -moz-linear-gradient(183deg, rgba(255,136,0,1) 54%,
    rgba(255, 146, 0,1) 90%);
    background: -webkit-linear-gradient(183deg, rgba(255,136,0,1) 54%,
    rgba(255, 146, 0, 1) 90%);
    background: linear-gradient(183deg, rgba(255,136,0,1) 54%,
    rgb(255, 146, 0) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#ff8800",endColorstr="#ff9200",GradientType=1);
    display: flex;
    justify-content: center;
    flex-grow: 0;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 20px rgba(0,0,0,0.4);
  }

  .footer ul li {
    list-style: none;
    display: flex;
    margin: 0 20px;
    font-size: 35px;
    line-height: 35px;
  }

  .footer ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .footer ul li a {
    color: #FFFFFF;
    text-decoration: none;
    transition: color 150ms linear;
  }

  .footer ul li a:hover {
    color: #FFE7CB;
  }
  .green img {
    max-height: 50px;
    vertical-align: middle;
    background: #FFFFFF;
    border-radius: 5px;
  }

  .green img:hover {
    background: #EEFFEE;
  }

  @media screen and (max-width: 500px) {
    .footer ul {
      max-width: 450px;
    }
    .green {
      order: 99;
      margin-top: 10px !important;
    }
  }

</style>
