<template>
  <div>
    <div class="about" id="about">
      <a class="goto" href="#about">
        <fa-icon :icon="['fas', 'chevron-down']" />
      </a>
      <div class="about-content">
        <p>
          Hi, I'm Sam. Nice to meet you.
        </p>
        <p>
          I've come a long way since my first simple website back in 2015, picking up amazing
          technologies including <strong>Django</strong>, <strong>VueJS</strong>,
          <strong>Laravel</strong>, and <strong>AWS</strong> along the way. I have not just focused
          on web development though, with my knowledge spanning into the realms of
          <strong>Java</strong>, <strong>C</strong>, <strong>C++</strong>, <strong>C#</strong>, and
          <strong>MySQL</strong>. I love a challenge, and I'm always looking to learn something new!
        </p>
      </div>
    </div>
    <div class="cards">
      <Card
        v-for="(card, index) in cards"
        :key="index"
        :icon="card.icon"
        :title="card.title"
        :body="card.body"
      />
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  name: 'About',
  components: {
    Card,
  },
  data() {
    return {
      cards: [
        {
          icon: ['fas', 'graduation-cap'],
          title: 'Education',
          body: [
            'I am currently completing the 3rd year of my masters degree in Computer Science at The University of Exeter.',
            'During my time at Exeter, I won 1st place in the group software engineering module during which I learned about Django, AWS Elastic Beanstalk, set up a CI/CD pipeline for the project and followed an agile development process.',
          ],
        },
        {
          icon: ['fas', 'desktop'],
          title: 'Work Experience',
          body: [
            'At present I am working as Head of Tech at Hatless Studios Ltd. In this role I oversee all projects and ensure all Developers and Project Leads are supported and sufficiently trained.',
            'I am also working with an Exeter startup called Neural Connections Ltd. which aims to produce a concussion warning device for athletes to reduce head trauma over their careers.',
          ],
        },
        {
          icon: ['fas', 'code-branch'],
          title: 'More About Sam',
          body: [
            'During my spare time I enjoy playing cricket, walking my dog, and learning about the fundamentals of how things work.',
            'I love helping people out with their code, and helping brainstorm ideas of ways to overcome problems.',
            'More recently I have become interested in learning to fly, and I am now working towards earning my NPPL(M)',
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>

  .about {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    background: #FF8800;
    background: -moz-linear-gradient(10deg, rgba(255,136,0,1) 54%,
    rgba(255,166,0,1) 79%);
    background: -webkit-linear-gradient(10deg, rgba(255,136,0,1) 54%,
    rgba(255,166,0,1) 79%);
    background: linear-gradient(10deg, rgba(255,136,0,1) 54%,
    rgba(255,166,0,1) 79%);
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="#ff8800",endColorstr="#ffa600",GradientType=1);
    box-shadow: 0 0 20px rgba(0,0,0,0.4);
  }

  .goto {
    display: flex;
    margin: 10px auto;
    color: #FFF;
    font-size: 6vh;
    line-height: 10vh;
    vertical-align: middle;
    padding: 0;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
    overflow: visible;
  }

  .goto svg {
    transition: transform 100ms ease;
  }

  .goto:hover svg {
    transform: translateY(3px);
  }

  .about-content {
    display: flex;
    flex-direction: column;
    width: 80vw;
    max-width: 840px;
    color: #FFF;
    margin: 0 auto;
    height: auto;
    padding-bottom: 100px;
  }

  .about-content p {
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: 400;
  }

  .about-content p:first-of-type {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 0;
  }

  .cards {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
      transform: translateY(-100px);
  }

  @media screen and (min-width: 1000px) {
    .cards {
      width: 60%;
      flex-direction: row;
    }
  }

</style>
