<template>
    <div class="card">
      <div class="icon">
        <fa-icon :icon="icon" />
      </div>
      <p class="card-title">
        {{ title }}
      </p>
      <div class="card-body">
        <p v-for="(p, index) in body" :key="index">
          {{ p }}
        </p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    icon: {
      type: [Object, Array],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    body: {
      type: [String, Array],
      required: true,
    },
  },
};
</script>

<style scoped>

  .card {
    display: inline-flex;
    flex-direction: column;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 15vw;
    min-width: 300px;
    margin: 20px auto;
    align-content: center;
  }

  @media screen and (max-width:1600px) and (min-width:1000px) {
    .card {
      margin: 20px;
    }
  }

  @media screen and (max-width: 1000px){
    .card {
      width: 60vw;
    }
  }

  .icon {
    display: flex;
    text-align: center;
    color: #FF8800;
    width: 100%;
    font-size: 2.5em;
    margin: 30px auto;
  }

  .icon svg {
    margin: 0 auto;
  }

  .card-body {
    display: flex;
    padding: 0 20px;
    color: #707070;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.8em;
    flex-grow: 1;
    flex-direction: column;
  }

  .card-body p {
    margin: 10px 0;
  }

  .card-body p:first-child {
    margin-top: 20px;
  }

  .card-body p:last-child {
    margin-bottom: 20px;
  }

  .card-title {
    display: flex;
    color: #696969;
    font-size: 1.2em;
    margin: 5px auto;
    font-weight: 500;
  }

</style>
