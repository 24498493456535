<template>
  <div id="app">
    <Navigation />
    <router-view class="page"/>
    <Footer />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page {
  flex-grow: 1;
  width: 100%;
}

html, body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
}

</style>
<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: { Footer, Navigation },
};
</script>
