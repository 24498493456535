import { tracking } from '@/api-routes';

export default {
  methods: {
    trackLink(link, page = null) {
      if (page === null) {
        // eslint-disable-next-line no-param-reassign
        page = this.$route.meta.id;
      }
      tracking(link, page);
    },
  },
};
