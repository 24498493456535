<template>
  <div class="contact">
    <h1>Get in touch</h1>
    <form class="contact_form" @submit.prevent="form_submit">
      <label class="required">Name</label>
      <input type="text" v-model="form_data.name"/>
      <label class="required">Email</label>
      <input type="email" v-model="form_data.email"/>
      <label>Phone</label>
      <input type="text" v-model="form_data.phone"/>
      <label>Position</label>
      <input type="text" v-model="form_data.position"/>
      <label class="required">Message</label>
      <textarea v-model="form_data.message"></textarea>
      <ReCaptcha />
      <div id="errors">
        <ul>
          <li v-for="(error, index) in errors" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
      <button type="submit" v-if="!loading">Submit</button>
      <div class="loading" v-if="loading">
        <loading-progress
          :progress="0"
          :indeterminate="true"
          size="24"
          rotate
          shape="circle"
          fillDuration="2"
          rotationDuration="1"
          :staticpath="true"
        />
      </div>
      <div id="success" v-if="success">
        <p>Message Sent!</p>
      </div>
    </form>
  </div>
</template>

<script>
import ReCaptcha from '@/components/Contact/ReCaptcha.vue';
import { contact } from '@/api-routes.js';

export default {
  name: 'Contact',
  components: {
    ReCaptcha,
  },
  data() {
    return {
      form_data: {
        name: '',
        message: '',
        email: '',
        position: '',
        phone: '',
      },
      errors: [],
      success: false,
      loading: false,
    };
  },
  methods: {
    clear_form() {
      this.form_data.name = '';
      this.form_data.message = '';
      this.form_data.email = '';
      this.form_data.position = '';
      this.form_data.phone = '';
    },
    form_submit(e) {
      e.preventDefault();
      // eslint-disable-next-line no-undef
      const capatcha = grecaptcha.getResponse();
      const data = new FormData();
      data.append('name', this.form_data.name);
      data.append('email', this.form_data.email);
      data.append('phone', this.form_data.phone);
      data.append('position', this.form_data.position);
      data.append('message', this.form_data.message);
      data.append('capatcha_token', capatcha);
      this.errors = [];
      this.success = false;
      this.loading = true;
      contact(data).then(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.reset();
        this.clear_form();
        this.success = true;
      }).catch((error) => {
        this.errors = error.response.data.errors;
      }).finally(() => {
        this.loading = false;
      });
      return false;
    },
  },
};
</script>

<style scoped>

  .contact {
    max-width: 600px;
    min-width: 344px;
    margin: 10px auto 60px;
    padding: 0 0 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }

  @media screen and (max-width: 666px) {
    .contact {
      max-width: 90%;
    }
  }

  .contact h1 {
    margin: 0;
    padding: 20px 0;
    color: #FFFFFF;
    font-family: "Open Sans", sans-serif;
    background: #ff8800;
  }

  .contact_form {
    margin: 0 20px 20px;
    min-width: 304px;
    display: flex;
    flex-direction: column;
    font-family: "Open Sans", sans-serif;
  }

  .contact_form label {
    margin-top: 20px;
    text-align: left;
    color: #707070;
  }

  .contact_form input, .contact_form textarea {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #707070;
    color: #707070;
    height: 1.8em;
    font-size: 1em;
    outline: none !important;
  }

  .contact_form textarea {
    height: 8em;
    font-family: "Open Sans", sans-serif;
    resize: none;
  }

  .contact_form button[type=submit] {
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #ff8800;
    color: #ff8800;
    height: 3em;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
    transition: background 0.1s linear, color 0.1s linear;
  }

  .contact_form button[type=submit]:hover {
    background: #ff8800;
    color: #FFFFFF
  }

  .contact_form .loading {
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #ff8800;
    color: #ff8800;
    height: 3em;
    font-size: 1em;
  }

  #errors ul {
    margin: 0 0 20px;
    padding: 0;
  }

  #errors ul li {
    list-style: none;
    color: #ff253a;
    text-align: left;
    font-family: "Open Sans", sans-serif;
  }

  #success p {
    margin: 20px 0 0;
    padding: 0;
    color: #42b983;
    text-align: left;
    font-family: "Open Sans", sans-serif;
  }

  .required::after {
    content: '*';
    color: #ff253a;
  }

</style>

<style>

  .vue-progress-path, .vue-progress-path svg {
    margin: 0 auto;
    height: 3em !important;
    width: 3em !important;
  }

  .vue-progress-path path {
    stroke-width: 2;
    fill: none;
  }

  .vue-progress-path .progress {
    stroke: #ff8800;
  }

  .vue-progress-path .background {
    stroke: #edd;
  }
</style>
